import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "cannondale" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cannondale-elcyklar"
    }}>{`Cannondale Elcyklar`}</h1>
    <p>{`Välkommen till vår sida dedikerad till Cannondale elcyklar – en av de mest pålitliga och innovativa varumärkena inom elcyklar. Här finner du en omfattande översikt över de olika serierna av Cannondale elcyklar och deras unika egenskaper. Upptäck vilken modell som passar dina behov bäst, oavsett om du söker en elcykel för stadspendling, terrängäventyr eller allroundbruk.`}</p>
    <h2 {...{
      "id": "cannondale-serier"
    }}>{`Cannondale Serier`}</h2>
    <h3 {...{
      "id": "cannondale-mavaro-neo"
    }}>{`Cannondale Mavaro Neo`}</h3>
    <p>{`Cannondale Mavaro Neo-serien erbjuder elcyklar med en kombination av stil och funktionalitet, perfekt för stadspendling och längre utflykter. Serien har ett elegant och användarvänligt design, vilket underlättar för daglig användning, med funktioner som:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bosch Active Line Plus`}</strong>{` motor`}</li>
      <li parentName="ul"><strong parentName="li">{`Darfon 360Wh`}</strong>{`-batteri för lång räckvidd`}</li>
      <li parentName="ul"><strong parentName="li">{`Hydrauliska skivbromsar`}</strong>{` och `}<strong parentName="li">{`Schwalbe Big Ben-däck`}</strong>{` för säkerhet och komfort`}</li>
      <li parentName="ul">{`Flera modeller med olika insteg och färgalternativ`}</li>
    </ul>
    <h3 {...{
      "id": "cannondale-trail-neo"
    }}>{`Cannondale Trail Neo`}</h3>
    <p>{`Cannondale Trail Neo-serien är idealisk för äventyrslystna cyklister som söker robusta elcyklar för varierande terränger. Med kraftfulla motorer och högpresterande komponenter är dessa elcyklar byggda för att klara av både stads- och skogsbruk.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bosch Performance CX`}</strong>{` motor`}</li>
      <li parentName="ul"><strong parentName="li">{`500Wh`}</strong>{` batteri för långvarig kraft`}</li>
      <li parentName="ul">{`Stora `}<strong parentName="li">{`29-tums Maxxis Ardent`}</strong>{` däck för bästa grepp och stabilitet`}</li>
      <li parentName="ul">{`Pålitligt `}<strong parentName="li">{`Shimano Deore`}</strong>{` växelsystem`}</li>
    </ul>
    <h3 {...{
      "id": "cannondale-moterra-neo"
    }}>{`Cannondale Moterra Neo`}</h3>
    <p>{`Cannondale Moterra Neo-serien representerar det bästa inom eldrivna mountainbikes, perfekt för att bemästra utmanande terräng och långa äventyr. Denna serie är utrustad med avancerade teknologier och komponenter för maximal prestanda.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Shimano EP8`}</strong>{` motor eller `}<strong parentName="li">{`Bosch Performance CX`}</strong>{` motor`}</li>
      <li parentName="ul">{`Högkapacitetsbatterier på upp till `}<strong parentName="li">{`750Wh`}</strong></li>
      <li parentName="ul">{`Heldämpade system för optimerad komfort och kontroll`}</li>
      <li parentName="ul"><strong parentName="li">{`Shimano XT/SLX`}</strong>{` drivlinor och robusta `}<strong parentName="li">{`29-tums`}</strong>{` däck från Maxxis`}</li>
    </ul>
    <h3 {...{
      "id": "cannondale-adventure-neo"
    }}>{`Cannondale Adventure Neo`}</h3>
    <p>{`Cannondale Adventure Neo-serien riktar sig till cyklister som söker bekväm och effektiv elcykel för både stad och landsväg. Dessa elcyklar erbjuder en mjuk och stabil åkning och kommer med praktiska funktioner som gör cykling till en fröjd.`}</p>
    <ul>
      <li parentName="ul">{`Kraftfulla `}<strong parentName="li">{`Bosch Active Line Plus`}</strong>{` motorer`}</li>
      <li parentName="ul"><strong parentName="li">{`Bosch PowerTube 400Wh`}</strong>{` batteri för lång räckvidd`}</li>
      <li parentName="ul">{`Ergonomisk design med bekväm sadel och greppvänliga handtag`}</li>
      <li parentName="ul">{`Intuitiv `}<strong parentName="li">{`Bosch Purion`}</strong>{` display för enkel hantering`}</li>
    </ul>
    <h3 {...{
      "id": "cannondale-tesoro-neo"
    }}>{`Cannondale Tesoro Neo`}</h3>
    <p>{`Cannondale Tesoro Neo-serien kombinerar premium prestanda med modern design, perfekt för både stadspendling och längre utomhusäventyr. Designad för att klara varierande landskap med lätthet.`}</p>
    <ul>
      <li parentName="ul">{`Avancerad `}<strong parentName="li">{`Bosch-motor`}</strong></li>
      <li parentName="ul">{`Högkapacitets `}<strong parentName="li">{`Bosch PowerTube-batteri`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Shimano`}</strong>{`-växlar och hydrauliska skivbromsar`}</li>
      <li parentName="ul">{`Robusta 29-tums hjul och högkvalitativa däck`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-välj-rätt-cannondale-elcykel"
    }}>{`Köpguide: Välj Rätt Cannondale Elcykel`}</h2>
    <p>{`Att välja rätt Cannondale elcykel beror på dina specifika behov och användningsområden. Här är några tips för att hitta den perfekta modellen för dig:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Stadspendling`}</strong>{`: Välj en modell från `}<strong parentName="li">{`Mavaro Neo`}</strong>{` eller `}<strong parentName="li">{`Adventure Neo`}</strong>{`-serien för stilren design och bekväm användning.`}</li>
      <li parentName="ol"><strong parentName="li">{`Terrängkörning`}</strong>{`: För robusta och äventyrsfärdiga alternativ, välj en modell från `}<strong parentName="li">{`Trail Neo`}</strong>{` eller `}<strong parentName="li">{`Moterra Neo`}</strong>{`-serien.`}</li>
      <li parentName="ol"><strong parentName="li">{`Allroundbruk`}</strong>{`: Letar du efter en flexibel elcykel som klarar både stad och landsbygd, då är `}<strong parentName="li">{`Tesoro Neo`}</strong>{` serien ditt bästa val.`}</li>
      <li parentName="ol"><strong parentName="li">{`Motor & Batteri`}</strong>{`: Tänk på elcykelns motor och batterikapacitet; högre Wh värden innebär längre räckvidd och mer kraft.`}</li>
      <li parentName="ol"><strong parentName="li">{`Komfort & Säkerhet`}</strong>{`: Se till att välja en elcykel med bra dämpning, pålitliga bromsar och bekväma sittpositioner för en säker och njutbar cykling.`}</li>
    </ol>
    <p>{`Oavsett vilket behov du har, hittar du alltid en passande modell hos Cannondale elcyklar. Utforska vårt sortiment och välj en elcykel som tar dina cykeläventyr till nästa nivå!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      